// react/src/pages/ExamPage.js
import React, { Component } from "react";
import ExamInstructions from "../components/ExamInstructions";
import EssayForm from "../components/EssayForm";
import Appfooter from "../components/Appfooter";
import Header from "../components/Header";
import axios from "../utils/axios";

class ExamPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showInstructions: true,
      showExamForm: false,
      essayTitle: "",
      essayText: "",
      isSubmitted: false,
      timeLeft: 14400,
      loading: false,
      error: "",
    };
  }

  componentDidMount() {
    this.checkSession();
  }

  checkSession = () => {
    const sessionExpiration = localStorage.getItem("sessionExpiration");
    if (!sessionExpiration || new Date() > new Date(sessionExpiration)) {
      this.props.history.push("/login");
    } else {
      // Se a sessão for válida, as instruções devem ser exibidas
      this.setState({ showInstructions: true });
    }
  };

  handleStartExam = () => {
    this.setState({ showInstructions: false, showExamForm: true });
    this.startFakeTimer();
  };

  startFakeTimer = () => {
    this.timer = setInterval(() => {
      const { timeLeft } = this.state;
      if (timeLeft > 0) {
        this.setState({ timeLeft: timeLeft - 1 });
      }
    }, 1000);
  };

  handleSubmitEssay = async (essayTitle, essayText) => {
    this.setState({ loading: true, error: "" });

    try {
      const response = await axios.post("/api/submit-essay/", {
        title: essayTitle,
        body: essayText,
      });

      if (response.status === 201) {
        alert("Sua redação foi enviada com sucesso!");
        this.setState({ isSubmitted: true });
      }
    } catch (error) {
      this.setState({
        error: "Erro ao enviar a redação. Tente novamente mais tarde.",
      });
    } finally {
      this.setState({ loading: false });
    }
  };

  render() {
    const {
      showInstructions,
      showExamForm,
      timeLeft,
      essayTitle,
      essayText,
      isSubmitted,
      loading,
      error,
    } = this.state;

    return (
      <div className="main-wrapper bg-lightblue">
        <Header divclassName="shadow-none" color="light" />
        <div className="container min-vh-100 align-items-center justify-content-center">
          {showInstructions && (
            <ExamInstructions onStartExam={this.handleStartExam} />
          )}

          {showExamForm && (
            <EssayForm
              essayTitle={essayTitle}
              essayText={essayText}
              timeLeft={timeLeft}
              isSubmitted={isSubmitted}
              onSubmit={this.handleSubmitEssay}
              loading={loading}
              error={error}
            />
          )}
        </div>
        {/* <Appfooter /> */}
      </div>
    );
  }
}

export default ExamPage;
