import React, { Component } from "react";
import { Link, withRouter } from "react-router-dom";
import axios from "../utils/axios";

class Header extends Component {
  state = {
    isOpen: false,
  };

  toggleOpen = () => this.setState({ isOpen: !this.state.isOpen });

  logoutUser = async () => {
    try {
      localStorage.removeItem("sessionExpiration");
      localStorage.removeItem("userType");

      await axios.post("/logout/");

      this.props.history.push("/login");
    } catch (error) {
      console.error("Erro ao fazer logout:", error);
      alert("Erro ao fazer logout. Por favor, tente novamente.");
    }
  };

  render() {
    const { divClass, color = "light" } = this.props;
    let colorClass;
    if (color === "dark") {
      colorClass = "text-white";
    }

    return (
      <div className={`header-wrapper pt-3 pb-3 shadow-xss ${divClass}`}>
        <div className="container">
          <div className="row">
            <div className="col-lg-9 navbar pt-0 pb-0 d-flex align-items-center">
              <Link to="/">
                <img
                  src="static/images/logo_company.png"
                  alt="Logo UniFatecie"
                  style={{ height: "60px", marginRight: "15px" }}
                />
              </Link>

              <button className="navbar-toggler" onClick={this.toggleOpen}>
                <span className="navbar-toggler-icon"></span>
              </button>
            </div>
            <div className="col-lg-3 text-right d-none d-lg-block">
              {/* Ícone de Sair com a função de logout */}
              <Link
                to="#"
                onClick={this.logoutUser}
                className={`float-right d-none d-lg-block text-center mt-1 ml-4 text-grey-800 ${colorClass}`}
              >
                <i className="ti-power-off font-lg"></i>
                <span className="font-xssss fw-500 d-block lh-1">Sair</span>
              </Link>

              {/* Ícone de WhatsApp */}
              <Link
                to="#"
                className={`float-right d-none d-lg-block text-center mt-1 ml-4 text-grey-800 ${colorClass}`}
              >
                <i className="ti-mobile font-lg"></i>
                <span className="font-xssss fw-500 d-block lh-1">Contato</span>
              </Link>

              {/* Ícone de Perfil */}
              <Link
                to="#"
                className={`float-right d-none d-lg-block text-center mt-1 ml-4 text-grey-800 ${colorClass}`}
              >
                <i className="ti-user font-lg"></i>
                <span className="font-xssss fw-500 d-block lh-1">Perfil</span>
              </Link>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default withRouter(Header);
