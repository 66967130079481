// react/src/components/ExamInstructions.js
import React from "react";

const ExamInstructions = ({ onStartExam }) => {
  return (
    <div className="container d-flex align-items-center justify-content-center min-vh-100">
      <div
        className="card w-100 border-0 bg-white shadow-xs p-0 mb-4"
        style={{ maxWidth: "800px" }}
      >
        <div className="card-body p-lg-5 p-4 w-100 border-0">
          <h2 className="font-xl text-center text-dark fw-700 mb-4">
            Diretrizes para o Vestibular
          </h2>

          <div className="diretrizes mb-4">
            <p className="text-grey-800 font-xs mb-2">
              <strong>1. Estrutura do Vestibular</strong>
            </p>
            <p className="text-grey-800 font-xs mb-2">
              i. O vestibular será composto exclusivamente por uma prova de
              redação em língua portuguesa de caráter eliminatório;
            </p>
            <p className="text-grey-800 font-xs mb-2">
              ii. A redação deverá ser escrita em texto único, com limite máximo
              de 20 linhas;
            </p>
            <p className="text-grey-800 font-xs mb-2">
              iii. O tema da redação será anunciado no início da prova;
            </p>
            <p className="text-grey-800 font-xs mb-4">
              iv. A redação deve abordar o tema proposto de forma clara e
              objetiva, demonstrando coerência e coesão textual;
            </p>
            <p className="text-grey-800 font-xs mb-2">
              <strong>2. Condições de Realização</strong>
            </p>
            <p className="text-grey-800 font-xs mb-2">
              i. A prova de redação será realizada em sessão única com duração
              de 4 horas;
            </p>
            <p className="text-grey-800 font-xs mb-4">
              ii. Os candidatos deverão utilizar o campo de edição de texto
              disponível para elaboração da redação e submeter o envio da prova.
            </p>
            <p className="text-grey-800 font-xs mb-2">
              <strong>3. Critérios de Avaliação</strong>
            </p>
            <ul
              className="text-grey-800 font-xs mb-2"
              style={{ paddingLeft: "20px", listStyleType: "disc" }}
            >
              <li>a. Clareza e objetividade na exposição de ideias;</li>
              <li>b. Coerência e coesão textual;</li>
              <li>c. Adequação ao tema proposto;</li>
              <li>d. Originalidade e criatividade na abordagem do tema;</li>
              <li>e. Correção gramatical e ortográfica.</li>
            </ul>
            <p className="text-grey-800 font-xs mb-4">
              iv. Os candidatos que tirarem nota inferior a 2,0 serão
              desclassificados automaticamente.
            </p>
            <p className="text-grey-800 font-xs mb-4">
              i. O resultado da prova de redação será divulgado no prazo de 5
              dias após a realização do vestibular.
            </p>
          </div>

          <div className="text-center">
            <button
              className="btn btn-lg bg-danger text-white fw-600 text-uppercase"
              onClick={onStartExam}
            >
              Iniciar Avaliação
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ExamInstructions;
