// react/src/components/EssayForm.js
import React, { useState } from "react";

const EssayForm = ({
  essayTitle,
  essayText,
  timeLeft,
  isSubmitted,
  onSubmit,
  loading,
  error,
}) => {
  const [title, setTitle] = useState(essayTitle);
  const [text, setText] = useState(essayText);

  const handleSubmit = (event) => {
    event.preventDefault();
    if (title && text) {
      onSubmit(title, text);
    } else {
      alert("Preencha todos os campos antes de enviar.");
    }
  };

  const formatTime = (seconds) => {
    const h = Math.floor(seconds / 3600);
    const m = Math.floor((seconds % 3600) / 60);
    const s = seconds % 60;
    return `${h.toString().padStart(2, "0")}:${m
      .toString()
      .padStart(2, "0")}:${s.toString().padStart(2, "0")}`;
  };

  return (
    <div className="container d-flex align-items-center justify-content-center min-vh-100">
      <div
        className="card w-100 border-0 bg-white shadow-xs p-0 mb-4"
        style={{ maxWidth: "800px" }}
      >
        <div className="card-body p-lg-5 p-4 w-100 border-0">
          <h2 className="font-xl text-center text-dark fw-700 mb-4">
            Prova de Redação
          </h2>

          <div className="text-center mb-4">
            <span className="badge badge-danger font-lg">
              Tempo Restante: {formatTime(timeLeft)}
            </span>
          </div>

          <div className="instructions mb-4">
            <h4 className="text-dark font-lg fw-600 mb-3">
              Tema da Redação: Desafios e Oportunidades da Transformação Digital
              na Sociedade Contemporânea
            </h4>
            <p className="text-grey-800 font-xs mb-3">
              Vivemos em uma era marcada pela rápida evolução tecnológica e pela
              transformação digital, que tem reconfigurado profundamente
              diversos aspectos da sociedade contemporânea. A integração de
              tecnologias digitais em nossas vidas diárias tem trazido desafios
              significativos, mas também abriu novas oportunidades em diferentes
              setores, como educação, trabalho e comunicação.
            </p>
          </div>

          {!isSubmitted ? (
            <form onSubmit={handleSubmit}>
              {error && (
                <div className="alert alert-danger font-xs mb-4">{error}</div>
              )}
              <input
                type="text"
                name="essayTitle"
                className="form-control mb-4 p-3 font-xs border-light bg-light text-center"
                placeholder="Insira um título"
                value={title}
                onChange={(e) => setTitle(e.target.value)}
                required
              />
              <textarea
                className="form-control mb-4 p-4 font-xs lh-28 border-light bg-light"
                rows="10"
                name="essayText"
                value={text}
                onChange={(e) => setText(e.target.value)}
                placeholder="Comece a digitar sua redação aqui..."
                required
              ></textarea>
              <button
                type="submit"
                className="form-control text-center style2-input text-white fw-600 bg-current border-0 p-0"
                disabled={loading}
              >
                {loading ? "Enviando..." : "Enviar Redação"}
              </button>
            </form>
          ) : (
            <div className="text-center">
              <div className="alert alert-success font-xs fw-500">
                <i className="feather-check-circle mr-2"></i>
                Redação enviada com sucesso! Aguarde o retorno da instituição
                via e-mail.
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default EssayForm;
