// react/src/utils/axios.js
import axios from "axios";
import { getCsrfToken } from "./csrf";

// const instance = axios.create({
//   baseURL: "https://vestibularunifatecie.com/api",
//   withCredentials: true,
//   headers: {
//     "X-CSRFToken": getCsrfToken(),
//   },
// });

const instance = axios.create({
  baseURL: "https://vestibularunifatecie.com/api",
  withCredentials: true,
  headers: {
    "X-CSRFToken": getCsrfToken(),
  },
});


instance.interceptors.request.use((config) => {
  config.headers["X-CSRFToken"] = getCsrfToken();
  return config;
});

export default instance;
